import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ContentLayout from "../components/ContentLayout"
import { Link } from "../components/fragments"

import Icon from "../svg/menu/egyetemunk.svg"

const shortcodes = {
  a: Link,
}

const InfoPage = ({
  data: {
    cover: { childImageSharp: cover },
    mdx: { body },
  },
}) => (
  <ContentLayout icon={Icon} title="Info" cover={cover.fluid}>
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  </ContentLayout>
)

export default InfoPage

export const query = graphql`
  query info {
    cover: file(relativePath: { eq: "covers/info.png" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mdx(slug: { eq: "info/main" }) {
      body
    }
  }
`
